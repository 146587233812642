import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1648394733/haiti_mt5xe7.jpg",
        "alt": "Image 2 of Haiti (105 Photographs), Library of Congress, Music Division, Federal Theatre Project Collection."
      }}></img></p>
    <p>{`Caribe, pareces tan lejano desde aquí y sin embargo tu gente, tu gastronomía, tu música y demás elementos conforman la identidad francesa. Estamos en la época del año en la que el frío se resiente hasta los tímpanos. El sol se sigue levantando a las 8:30 am y se acuesta antes de que termine la jornada laboral. La falta de luz repercute en el estado de ánimo general (sin mencionar los ajustes, reajustes y desbarajustes creados por el COVID). En este contexto helado y radicalmente opuesto, cuando las editoras me pidieron dedicarle esta entrada al Caribe, lo primero que hice fue apurar un vaso de grog (bebida caliente de ron, limón y miel) y pensar en qué sería de nosotres sin esa bebida caribeña que de vez en cuando se antoja para sobrellevar estos fríos europeos. ¿Por dónde empezar? Como siempre, la respuesta vino durante una de mis deambulaciones.`}</p>
    <p>{`Habría que comenzar diciendo que el Caribe desembarcó en Burdeos, por ahí del siglo XVI, primero con intercambios comerciales, más tarde en el XVII, cuando Burdeos abrió líneas navieras regulares con las Antillas, abasteció a gran parte de Europa con productos coloniales como café, cacao y azúcar. El río Garona, fue en otro tiempo, escenario de intercambio del amargo alfeñique que modeló el rostro de la economía y la sociedad de las llamadas `}<em parentName="p">{`îles de sucre`}</em>{` (Islas azucareras).`}</p>
    <p>{`Abordar el Caribe supone acercarse a un terreno cuya complejidad nace desde su situación y características geográficas, de hecho, no podemos hablar de uno sino de los Caribes. El carácter insular y continental que lo ubica en la encrucijada del comercio e intercambios intercontinentales desde el siglo XVI se refleja en la importante diversidad lingüística criolla e indígena que lo conforma, así como sus seis lenguas oficiales (español, francés, holandés, inglés, criollo haitiano y papiamento). El Caribe sigue siendo un espacio enigmático para el resto del mundo, este misterio resalta en la manera misma en que lo nombramos, decididamente desde una fragmentaria mirada colonial: Caribe insular y Caribe continental, Antillas mayores, Antillas menores, Antillas españolas, Antillas francesas, etc.`}</p>
    <p>{`La situación estratégica de Burdeos y las islas creó una relación de vasos comunicantes indisociable entre una y otras, los intercambios entre estos espacios son añejos. Y al mirar alrededor, se puede constatar que el Caribe continúa cincelando la geografía de esta ciudad. Aunque estuve tentada a dedicar esta entrada a la `}<em parentName="p">{`Semaine de l’Amérique Latine et des Caraïbes`}</em>{` (SALC), una semana durante la cual Burdeos se viste de Caribe desde hace cuatro años. Sin embargo, esperaré al verano para hablarles de ello.`}</p>
    <p>{`Hoy he decidido enfocarme en tres espacios `}<em parentName="p">{`bordelais`}</em>{`, unidos por la presencia de la histórica familia Louverture. El primero de ellos es el `}<em parentName="p">{`Square Taussaint Louverture`}</em>{`, una plaza que se encuentra en la ribera derecha del río. Para llegar a ella hay que caminar un pasaje cubierto por árboles, que en los meses del otoño doran sus hojas y bajo ellos parecen desdoblar una extensa alfombra marchita para los caminantes. Hoy se intercala un poco el verdor y mucho los muñones invernales.`}</p>
    <p>{`En medio de la plaza se erige el busto del héroe de la revolución haitiana. Nada más y nada menos que la primera revuelta de esclavos de la época moderna cuyo éxito trascendió hasta lograr la Independencia de la isla de Saint-Domingue, hoy día, República de Haití. Desgraciadamente, Toussaint Louverture fue capturado y deportado a Francia, dónde terminó sus días y ya no alcanzó a ver la proclamación de la independencia. Pero quien, a través de su Carta a la Asamblea General de 1792, fue el primero, junto con Jean François Biassou, en encarar el racismo y la esclavitud coloniales.`}</p>
    <p>{`El busto de este héroe fue un regalo de la República de Haití a la ciudad de Burdeos, en conmemoración del bicentenario de su fundación. Detrás de este gesto de hermandad, radica el hecho de que Issac Louverture, hijo del independentista, vivió y falleció aquí. Su tumba se encuentra en el Cementerio de la Chartreuse; y la casa en la que habitó, todavía está en pie. Una fachada de piedra blanca intacta, ubicada en el 44 de la calle Faundaudège. Esta última puede mirarse desde los vagones de la línea del tranvía que pasa frente a ella. Imagino que desde ahí Issac Louverture escribió algunas de las cartas del extenso intercambio epistolar que le permitió mantener relación con su tierra natal. Este estrecho vínculo con la palabra me hace pensar en una conocida frase de Jean Jacques Pierre-Paul, poeta haitiano contemporáneo que tiene la particularidad de escribir en español desde Valparaíso. Pierre-Paul, asegura que “(...) es casi imposible ser haitiano sin ser poeta, anticolonialista y cazador de dictadores.” Estas tres características parecen, en efecto, necesarias cuando observamos la actual situación de Haití, un estado en crisis general. Me he dado cuenta de que Francia está más al tanto del contexto haitiano que la misma Latinoamérica.`}</p>
    <p><strong parentName="p">{`El pasado común y la lengua son determinantes, eso está claro. Aunque no deja de ser paradójico que, aunque Haití sea considerado como país integrante de LATAM, lo conozcamos tan poco.`}</strong>{` En este sentido, la frase de Pierre-Paul parece revelarse como una promesa, pues es mediante la literatura que Haití nos devela su realidad. En los últimos años los escritores haitianos ya ocupaban importantes espacios en la literatura francófona, pensemos en René Depestre, Danny Laferrière y Yanick Lahens, por citar a les más conocides. Parece ser que una nueva generación de escritores y poetas haitianes ocupa la escena literaria de diversos países hispanohablantes. Apropiándose de la lengua del país de acogida, abordan la crudeza del contexto a través de narraciones o poemas mordaces. Entre ellos sobresalen el anteriormente citado Jean Jacques Pierre-Paul desde Chile, la artista y novelista Micheline Dusseck desde España y Ustin Pascal Dubuisson desde México. Comencé buscando el Caribe en la ciudad, y he terminado encontrándolo en todas partes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      